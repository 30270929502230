/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link, graphql } from 'gatsby'
import { ChevronLeft } from 'react-feather'
import dayjs from 'dayjs'
import ShareButtons from '../components/Share'

import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'

const SinglePostTemplate = ({ data, extraComponent, state }) => {
  const retUrl = state[0] ? '/' : '/events'

  const { title, eventDate, body } = data
  return (
    <main>
      <article
        className="SinglePost section light"
        itemScope
        itemType="http://schema.org/BlogPosting"
      >
        <div className="container skinny">
          <Link className="SinglePost--BackButton" to={retUrl}>
            <ChevronLeft /> BACK
          </Link>
          <div className="SinglePost--Content relative">
            <div className="SinglePost--Meta">
              {eventDate && (
                <div
                  style={{
                    fontSize: 23,
                    fontWeight: 'bold',
                    padding: 4,
                    marginLeft: 8,
                    color: '#606a2b'
                  }}
                >
                  {dayjs(eventDate).format('dddd, MMMM D YYYY')}
                </div>
              )}
            </div>

            {title && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}

            <div className="SinglePost--InnerContent">
              <Content body={body} />
              {extraComponent}
            </div>
          </div>
        </div>
      </article>
    </main>
  )
}

// Export Default SinglePost for front-end
const SinglePost = ({ location, data: d }) => {
  const data = d.directus.events[0]
  const title = `Read ${data.title} `
  const url = location.href
  const twitterHandle = '_MsLinda'
  const { state = {} } = location
  // const { tags } = post.frontmatter
  return (
    <Layout
      meta={{
        title: data.title,
        description: data.shortDescription,
        url,
        image: data.featuredImage
      }}
    >
      <SinglePostTemplate
        data={data}
        state={state}
        extraComponent={
          <div
            style={{
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: '300',
              marginTop: '64px',
              color: 'rgb(54 55 113)'
            }}
          >
            <span>SHARE THIS NEWS</span>
            <ShareButtons
              title={title}
              url={url}
              twitterHandle={twitterHandle}
              tags={null}
            />
          </div>
        }
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file

  query SinglePost($slug: String!) {
    directus {
      events(filter: { slug: { _eq: $slug } }) {
        shortDescription
        SEO {
          title
          description
          image {
            id
          }
        }
        title
        featuredImage {
          id
        }
        body
        eventDate
        slug
      }
    }
  }
`
